<template>
    <!--
    backgroundColor必须设置，由于左边最多240，el-menu不知道怎么回事，也就最多240的宽度了，当menu级次很多的时候，那么就会出现水平滚动条，
    menu有背景，但是div溢出的部份是没有背景的，这样就很难看了，所以把div的背景搞得和menu的背景一样，这样溢出的时候，那部份也有背景
    -->
    <div :class="slideBarClass">
        <div class="slideLogo" v-if="otherParams.showLogo">
            <img :src="otherParams.logoPng" style="height:30px;cursor: pointer;" @click="$router.push('/')"/>
            <h5 v-if="otherParams.showTitle">{{otherParams.systemTitle}}</h5>
        </div>
        <el-menu
                :collapse="isCollapse"
                :default-active="route.name"
                class="menuCss"
                background-color="var(--el-color-self-slide-bg)"
                text-color="#fff"
                active-text-color="#409eff"
                :collapse-transition="false"
        >

            <!--
            我以为在MenuItem那里已经有获取userMenus的方式了，那么就可以不用在这里传入了，
            结果就出问题了，在top那里折叠菜单的时候，就会出现空白，所以这里还必须为MenuItem传入userMenus，
            不能在slidebar这里省略掉,但是如果传入所有的userMenus，又会有问题，当没有中间层，登录之后直接跳入到主页，
            这种情况下，如果userMenus有值，比如直接等于sessionStore里面的值，会出现每个顶级菜单下面都有整个项目的菜单。
            于是我就想了一个办法，把userMenus变为计算属性，当菜单折叠的时候，就让它等于sessionStore里面菜单，当菜单没有折叠，
            则传入一个空数组到MenuItem，这样就不会出现那样的情况了，折叠的时候，MenuItem也会有值了
            -->
            <MenuItem :userMenus="userMenus" ref="menuItemRef"/>
        </el-menu>
    </div>
</template>

<script>
    import MenuItem from "@/components/layout/MenuItem";
    import {reactive, toRefs, nextTick, onMounted, computed, getCurrentInstance, defineComponent, inject,watch} from 'vue';
    import {useRoute} from "vue-router";
    import config from "@/utils/config";
    import main_logo from '@/assets/main_logo.png';
    export default defineComponent({
        name: "SlideBar",
        setup(){
            const {proxy} = getCurrentInstance();
            const utils=proxy.utils;
            const route = useRoute();
            let mainComp=inject('mainComp');
            const dataObj=reactive({
                menuItemRef:null,
                isCollapse: false,
                amountFlag:false,
                otherParams:{
                    systemTitle:config.title,
                    showLogo:!mainComp.otherParams.showTop,
                    logoPng: main_logo,
                    showTitle:true,
                }
            });
            //如果要显示顶部，就不显示左边栏的logo了
            watch(() => mainComp.otherParams.showTop,async (newValue,oldValue) => {
                dataObj.otherParams.showLogo=!newValue;
            });
            //如果折叠了左边栏，那么就不显示左边栏的系统标题了
            watch(() => dataObj.isCollapse,async (newValue,oldValue) => {
                dataObj.otherParams.showTitle=!newValue;
            });
            onMounted(()=>{
                dataObj.amountFlag=true;
                nextTick(()=>{
                    let _systemTitle=sessionStorage.getItem('systemTitle');
                    if(_systemTitle)dataObj.otherParams.systemTitle=_systemTitle;
                })
            });
            //---------------------------computed------------
            //本来不想在这里为MenuItem传入menus的，因为在MenuItem里面自己去获取过menus，如果这里也传入menus会造成显示不正常，每个一级菜单下面会显示全部菜单。
            //但是这里不传入菜单，在左边折叠之后，就不会有菜单显示了，也不对。怎么办呢？于是在这里就加入计算属性，折叠了就传入menus，
            //                没有折叠的时候传入空，就相当于什么都没有传，让MenuItem来处理菜单的显示，因为它里面有获取菜单的方法
            //为什么又加入 || dataObj.amountFlag呢？因为我发现当你进入主页之后，你点击折叠然后再打开，这个时候什么菜单就没有了，因为你不加 || dataObj.amountFlag的时候，
            //                直接返回[],所以什么都没有了，所以这里加入判断，当加载了组件之后，折叠再展开的时候，仍然传入菜单，放心，这个时候，MenuItem不会合并菜单，显示是正常的
            const userMenus=computed(()=>{
                if(dataObj.isCollapse || dataObj.amountFlag){
                    nextTick(()=>{
                        if(sessionStorage.getItem("slideUserMenus")){
                            //当启用主页动画的时候，会调用两遍这个方法，第一次会进入最后，返回[],第二次会进入这里,
                            //但是在MenuItem组件那里只会把第一次的[]赋值给它的userMenus，这样就会导致左侧菜单不显示，
                            //所以才会出现dataObj.menuItemRef.userMenus=userMenus;这句话。不启用主页动画可以不要这句话
                            let userMenus=JSON.parse(utils.UtilPub.decrypt(sessionStorage.getItem("slideUserMenus")));
                            dataObj.menuItemRef.userMenus=userMenus;
                            return userMenus;
                        }else{
                            let userMenus=JSON.parse(utils.UtilPub.decrypt(sessionStorage.getItem("userMenus")));
                            dataObj.menuItemRef.userMenus=userMenus;
                            return userMenus;
                        }
                    })
                }else{
                    return [];
                }
            })
            const slideBarClass=computed(()=>{
                return {
                    slideBar_big: !dataObj.isCollapse,
                    slideBar_small: dataObj.isCollapse
                };
            })
            const changeMenu=(menus)=>{//动态更改左侧菜单，由top组件调用
                dataObj.menuItemRef.userMenus=menus;
            }
            return{
                ...toRefs(dataObj),userMenus,slideBarClass,changeMenu,route
            }
        },
        components: {
            MenuItem
        }
    });
</script>

<style scoped lang="scss">
    .slideBar_big {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        .slideLogo{
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            background-color: var(--el-color-self-slide-bg);
        }
    }

    .slideBar_small {
        position: fixed;
        width: $slide-collapse;
        height: 100%;
        overflow-y: auto;
        .slideLogo{
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            background-color: var(--el-color-self-slide-bg);
        }
    }

    .menuCss {
        height: 100%;
        border-right: none !important;
    }

    /*.menuCss ::v-deep(.el-submenu__title:hover){*/
    /*    background-color: rgb(40, 53, 79) !important;*/
    /*}*/

    /*.menuCss ::v-deep(.el-menu-item:hover){*/
    /*    background-color: rgb(40, 53, 79) !important;*/
    /*}*/
</style>
import {defineComponent, getCurrentInstance, reactive, toRefs,onMounted,onBeforeMount, onActivated,nextTick} from "vue";
import BookEffectUtil,{IBookEffectDataObj} from "./bookEffectUtil";
import config from "@/utils/config";
export default defineComponent({
    name: "BookEffect",
    setup(props,context){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        const bookEffectParams:any=context.attrs.bookEffectParams;
        const dataObj:IBookEffectDataObj=reactive<IBookEffectDataObj>({
            utilInst:{} as any,
            refMap:new Map(),
            effectOver:false,
            needEffect:utils.Tools.isAnimationOpen('mainAnimation') && config.flag1,//是否需要主页动画
            isRemotePic:false,
            message:sessionStorage.getItem('mainMsg')?sessionStorage.getItem('mainMsg')as string:'每天努力一点点',
            effectImages:[]
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new BookEffectUtil(proxy,dataObj);
        })
        onMounted(async()=>{
             await nextTick(async()=>{
                if(dataObj.needEffect){
                    //初始化翻书效果资源
                    await dataObj.utilInst.configLastLiBgPic();
                    //翻书效果最后一帧动画由.page1飞出去结束，为.page1添加动画执行完毕事件，当.page1结束动画之后，则整个翻书效果就完毕了，就可以显示主页了
                    let page1:any=document.querySelector(".page1");
                    page1.addEventListener("transitionend",function(){
                        //翻书效果完了之后，需要把主页显示出来
                        // dataObj.needEffect=false;
                        dataObj.effectOver=true;
                        //消除bookEffect的整个小太阳
                        dataObj.utilInst.configDomStyle('.bookEffect','background-image:none;');
                        bookEffectParams.inst.effectOver();
                    });
                    //注意：下方不睡眠一会儿，直接执行的话，不会出现翻书动画，fuck，折腾我好久，如果200不行，可以试着调整一下时间
                    setTimeout(()=>{
                        //开始动画
                        dataObj.utilInst.doBookEffect();
                    },200)
                }
            })
        })
        return{
            ...toRefs(dataObj)
        }
    }
});
import {mapActions, useStore} from "vuex";
import {getCurrentInstance, onBeforeUpdate, onMounted, watch, defineComponent, inject, ref} from "vue";
import {RouteLocationNormalizedLoaded, useRoute} from "vue-router";
let dropdownRefs: any[] = [];//没有什么卵用，目前用来点击另外一个右键菜单的时候，把前一个右键菜单给关闭掉
export default defineComponent({
    name: "Tabs",
    setup(){
        let mainComp=inject('mainComp') as any;
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        const route = useRoute();//当前路由对象
        let store = useStore();
        let visitedTabsView = utils.Tools.getPropFromVuex('visitedTabsView');
        //调用mutations中的方法
        // let mutations=mapMutations(['closeAllTabViews']);
        // let closeAllTabViews = mutations['closeAllTabViews'].bind({ $store: store });

        //调用actions中的方法
        let actions=mapActions(["addVisitedTabsView","closeSpeTabsView","closeAllVisitedTabsView","closeOtherTabsView"]);
        // vuex源码中mapState和mapGetters的方法中使用的是this.$store,所以更改this绑定
        let addVisitedTabsView = actions.addVisitedTabsView.bind({ $store: store });
        let closeSpeTabsView = actions.closeSpeTabsView.bind({ $store: store });
        let closeAllVisitedTabsView = actions.closeAllVisitedTabsView.bind({ $store: store });
        let closeOtherTabsView = actions.closeOtherTabsView.bind({ $store: store });

        onBeforeUpdate(() => {
            dropdownRefs = []
        })
        onMounted(async ()=>{

        })
        //根据当前访问的路由激活顶部菜单第一层菜单
        const doActiveTopMenu=()=>{
            if(mainComp.topRef)mainComp.topRef.doActiveTopMenu()
        }
        //监听路由变化，只要路由变化就把新的路由添加到我们的已访问路由数组中,如果是以顶部菜单显示，还需要高亮显示顶部菜单
        watch(() => route.path,async () => {
                if('/lockScreen'!=route.fullPath)await addVisitedTabsView(route);//添加的时候会判断是否已添加过了
                doActiveTopMenu();
            }
        )
        //判断传入的路由对象是否是当前路由，高亮显示当前路由的时候用
        const isActive=(curRoute:any)=>{
            return curRoute.path === route.path || curRoute.title === route.meta.title;
        }
        //关闭tab事件
        const closeHandler=async (tag:RouteLocationNormalizedLoaded)=>{
            if(tag.path==='/welcome')return;
            let visitedTabsView= await closeSpeTabsView(tag);//关闭后还剩哪些tabs
            // 如果关闭的是当前显示的页面，就去到前一个 tab-view 页面
            if (isActive(tag)) {
                const lastTag = visitedTabsView.slice(-1)[0];//取出关闭之后剩余tab中的最后一个tab
                lastTag?proxy.$router.replace(lastTag.path):proxy.$router.replace("/");
            }
        }
        //刷新tab事件
        const refreshHandler=(tag:RouteLocationNormalizedLoaded)=>{
            if (tag.path == proxy.$router.currentRoute.value.path){//如果刷新的是当前路由，则重新加载一下
                mainComp.contentRef.reload()
            } else {//如果刷新的不是当前路由，则直接跳转到要刷新的路由即可
                proxy.$router.replace(tag.path);
            }
        }

        //右键菜单里面的具体点击事件
        const dropCommand=async (event:any, path:string, index:number)=>{
            let route;
            switch (event) {
                case 'closeCurrent'://关闭当前
                    route=visitedTabsView.find((item:any)=>item.path==path)
                    await closeHandler(route);
                    break;
                case 'closeOthers'://关闭其他
                    route=visitedTabsView.find((item:any)=>item.path==path)
                    await closeOtherTabsView(route);
                    proxy.$router.replace(route.path);
                    break;
                case 'closeAll'://关闭所有
                    await closeAllVisitedTabsView();
                    await proxy.$router.push("/welcome");
                    break;
                case 'closeLeft'://关闭左侧所有
                    let delRoutes=visitedTabsView.filter((item:any,i:number)=>i<index);
                    for (const route of delRoutes) {
                        await closeHandler(route);
                    }
                    break;
                case 'closeRight'://关闭右侧所有
                    let _delRoutes=visitedTabsView.filter((item:any,i:number)=>i>index);
                    for (const route of _delRoutes) {
                        await closeHandler(route);
                    }
                    break;
            }
        }

        //右键菜单显示/隐藏事件
        const dropVisibleChange = (v: boolean, path: string) => {
            if (v) {//当右键菜单显示的时候
                dropdownRefs.forEach((item:any, i:number) => {
                    if (item.id != path)item.handleClose()
                })
            }
        }
        //把右键菜单添加到引用数组里面，添加的时候判断数组里面是否存在了，存在则不能重复添加，否则右键菜单出现不了，很奇怪，回重复往里面添加
        const setDropdownRef = (el: any) => {
            if (el) {
                let exist=dropdownRefs.some((item:any)=>item.id==el.id);
                if(!exist)dropdownRefs.push(el)
            }
        }
        return{
            visitedTabsView,isActive,closeHandler,refreshHandler,doActiveTopMenu,dropCommand,
            dropVisibleChange,setDropdownRef
        }
    },
});
import BaseBean from "@/utils/BaseBean";
import {nextTick} from 'vue';
export interface IMainDataObj {
    utilInst:MainUtil
    refMap:Map<string,any>
    contentClassFlag: boolean
    isEffectOver: boolean
    bookEffectParams:any
    prop:any
    showLayer:string | null//是否显示layer
    otherParams:any
}
export default class MainUtil extends BaseBean{
    public appInstant:any;
    public dataObj:IMainDataObj;

    constructor(proxy:any,appInstant:any,dataObj:IMainDataObj) {
        super(proxy);
        this.dataObj=dataObj;
        this.appInstant=appInstant;
    }
    //如果是从锁屏页面通过解锁进入main，则改变锁屏对象lockScreen的锁屏状态，然后跳转到锁屏时的路由进行路由恢复
    public recoverLockScreenState():void{
        nextTick(async ()=>{
            let lockScreen:ILockScreen=JSON.parse(sessionStorage.getItem("lockScreen") as string);
            if(lockScreen!=null && lockScreen.lockScreenState=='locked'){
                lockScreen.lockScreenState='unLocked';
                sessionStorage.setItem("lockScreen",JSON.stringify(lockScreen));
                this.dataObj.isEffectOver=true;
                await this.proxy.$router.replace({path: lockScreen.currentRoute as string});
            }
        })
    }
    //检查websocket对象状态，如果失效了则重新创建
    public async reCreateWebSocket():Promise<void>{
        if(!this.proxy.$socket){
            let usercode=(JSON.parse(this.utils.UtilPub.decrypt(sessionStorage.getItem("userInfo") as string)) as IStoreUserInfo).usercode;
            await this.utils.Websocket.createWebSocketCon(usercode,this.appInstant);
            await this.utils.Websocket.initWebSocket(this.appInstant);
        }
    }

    //初始化看板娘
    public initLive2d():void{
        let options={folderName:'shizuku',modelName:'shizuku'};//默认看板娘
        let live2d:any=localStorage.getItem('live2d');//如果之前设置过，则取设置过的值
        if(live2d){//设置过看板，且设置的是要展示
            live2d=JSON.parse(live2d);
            this.dataObj.otherParams.live2d.closeType=live2d.closeType;
            if(parseInt(live2d.closeType)!=2){
                if(live2d.value){//有可能一次都没有设置过，就暂时关闭了
                    this.dataObj.otherParams.live2d.currentLive2d=live2d.value;//更改当前选中值，下拉框号展现
                    //由于有些model和文件夹名称不一致，所以才需要查找一遍
                    let item=this.dataObj.otherParams.live2d.data.find((item:any)=>item.value==this.dataObj.otherParams.live2d.currentLive2d);
                    options={folderName:item.folderName,modelName:item.value};
                }
                this.createLive2d(options)
            }
        }else if(!live2d && this.dataObj.otherParams.live2d.closeType=='0'){//没有设置过看板，且要展示
            this.createLive2d(options)
        }
    }
    //初始化看板娘
    public createLive2d(options:any):void{
        // window.L2Dwidget.on('*', (name:any) => {//此处是对点击人物时触发事件的监听
        //     console.log('%c 你点击了 ' + '%c -> ' + name, 'background: #222; color: yellow','background: #fff; color: #000')
        // }).init({
        // @ts-ignore
        window.L2Dwidget.init({
            "model": {
                jsonPath: "/plugins/live2dw/live2d-widget-model-"+options.folderName+"/assets/"+options.modelName+".model.json",
                "scale": 1
            },
            "display": {
                "position": "left",
                "width": 300,//150
                "height": 600,//300
                "hOffset": 0,
                "vOffset": -20,
                zIndex: -1
            },
            "dialog":{
                enable: true,
                script: {
                    //每20s，显示一言（调用一言Api返回的句子）
                    'every idle 20s': '$hitokoto$',
                    //触摸到class='star'对象
                    'hover .star': '星星在天上而你在我心里 (*/ω＼*)',
                    //触摸到身体
                    'tap body': '害羞⁄(⁄ ⁄•⁄ω⁄•⁄ ⁄)⁄',
                    //触摸到头部
                    'tap face': '~~'
                }
            },
            "mobile": {//移动端配置
                "show": true,
                "scale": 0.5
            },
            "react": {
                opacity: 0.3,//0.5
                "opacityDefault": 0.2,
                "opacityOnHover": 0.2
            }
        });
    }
    //看板操作设置
    public configLive2d():void{
        let storeLive2d={closeType:this.dataObj.otherParams.live2d.closeType}
        switch (storeLive2d.closeType) {
            case "0"://不关闭
                let item=this.dataObj.otherParams.live2d.data.find((item:any)=>item.value==this.dataObj.otherParams.live2d.currentLive2d);
                let options={folderName:item.folderName,modelName:this.dataObj.otherParams.live2d.currentLive2d};
                this.createLive2d(options);//这句话为什么不能少？
                let jsonPath="/plugins/live2dw/live2d-widget-model-"+options.folderName+"/assets/"+options.modelName+".model.json";
                // @ts-ignore
                window.L2Dwidget.config.model.jsonPath=jsonPath;//这句话也tmd不能少
                localStorage.setItem('live2d',JSON.stringify(Object.assign(storeLive2d,item)));
                break;
            case "1"://暂时关闭
                //由于是暂时关闭，将来为了恢复现场，需要把之前存起来的给保存起来
                let storeLive2d_temp=localStorage.getItem('live2d');
                if(storeLive2d_temp){//之前设置过
                    storeLive2d_temp=JSON.parse(storeLive2d_temp);
                    Object.assign(storeLive2d_temp,storeLive2d);
                    localStorage.setItem('live2d',JSON.stringify(storeLive2d_temp));
                }else{//之前没有设置过
                    localStorage.setItem('live2d',JSON.stringify(storeLive2d));
                }
                let live2d_widget:any=document.querySelector('#live2d-widget');
                if(live2d_widget)live2d_widget.remove();
                break;
            case "2"://永久关闭
                localStorage.setItem('live2d',JSON.stringify(storeLive2d));
                let live2d_widget2=document.querySelector('#live2d-widget');
                if(live2d_widget2)live2d_widget2.remove();//销毁看板娘(注意，不能在dataObj.utilInst.createLive2d后调用，因为这个会先执行)
                break;
        }
        this.dataObj.otherParams.live2d.configVisible=false
    }
}
import BaseBean from "@/utils/BaseBean";
import _5 from '@/assets/picResources/5.jpg';
import _4 from '@/assets/picResources/4.jpg';
import _3 from '@/assets/picResources/3.jpg';
import _2 from '@/assets/picResources/2.jpg';
import _1 from '@/assets/picResources/1.jpg';
export interface IBookEffectDataObj {
    utilInst:BookEffectUtil
    refMap:Map<string,any>
    effectOver:boolean//翻书动画是否结束
    needEffect:boolean//是否需要动画效果
    isRemotePic:boolean//图片是否来自于远程
    message:string//文字提示
    effectImages:Array<string>//翻书动画图片数组
}
export default class BookEffectUtil extends BaseBean {
    public dataObj: IBookEffectDataObj;

    constructor(proxy: any, dataObj: IBookEffectDataObj) {
        super(proxy);
        this.dataObj = dataObj;
    }
    //设置指定元素的css
    public configDomStyle(selEx:string,styleStr:string):void{
        let dom: any = document.querySelector(selEx);//如果把这两行写在一起，ts会报错，fuck
        dom.style = styleStr;
    }
    //1、初始化effectImages数组，往里面放如要参与翻书效果图片的地址
    //2、设置最后一个li的背景图片。由于所有li堆在一起，所以只需要设置最后一个li的背景图片，相当于设置书本的封面
    public configLastLiBgPic():void{
        if(this.dataObj.isRemotePic){//图片来自于远程
            let _imageSource=sessionStorage.getItem("imageSource")as string;
            let imageSource= JSON.parse(this.utils.UtilPub.decrypt(_imageSource)) as Array<ResourceFileBean>;
            //从sessionStorage中取出图片，设置到每个li中，如果图片不足5张，则用最后一张补齐5张
            let num:number=0;
            for(let i=0;i<imageSource.length;i++){
                num++;
                this.dataObj.effectImages[i]=imageSource[i].path;
                if(num==5)break;
            }
            //图片不足5张，则用图片资源最后一张来填补上5张(有可能一张图片资源都没有，所以要判断图片资源是否大于0)
            //当然如果一张图片资源都没有，则不会又翻书效果了
            if(num<5 && imageSource.length>0){
                for(let i=num;i<5;i++){
                    this.dataObj.effectImages[i]=imageSource[imageSource.length-1].path;
                }
            }
            //如果一张图片资源都没有，则不会设置最后一个li的背景图片
            if(this.dataObj.effectImages.length==5)
            this.configDomStyle('.page5','background-image:url('+this.dataObj.effectImages[4]+');')
        }
        //翻书图片来自于本地或者远程根本就没有图片，那么也会采用本地图片来代替
        if(!this.dataObj.isRemotePic || this.dataObj.effectImages.length==0){
            this.dataObj.effectImages.push(_1,_2,_3,_4,_5);
            //设置最后一个li的背景图片
            this.configDomStyle('.page5','background-image:url('+_5+');');
        }
    }
    //翻书动画
    public doBookEffect():void{
        //1、ul推到50deg，相当于把书本摊平放在地面上
        this.configDomStyle('.book','transform: rotateX(50deg);transform-style: preserve-3d;')
        //2、ui推到之后，设置每个li的背景图片，并让他们按Y轴旋转一定角度且开始旋转的时间不同，这样就实现了翻书效果
        this.configDomStyle('.page5','background-image:url('+this.dataObj.effectImages[4]+');transition: all 1s 1s;transform: rotateY(-180deg);');
        this.configDomStyle('.page4','background-image:url('+this.dataObj.effectImages[3]+');transition: all 1s 2s;transform: rotateY(-150deg);');
        this.configDomStyle('.page3','background-image:url('+this.dataObj.effectImages[2]+');transition: all 1s 3s;transform: rotateY(-110deg);');
        this.configDomStyle('.page2','background-image:url('+this.dataObj.effectImages[1]+');transition: all 1s 4s;transform: rotateY(-90deg);');

        //3、上面翻书一共用了4s，以下延时0.5s才影藏那几个li，否则翻完倒数第二张图片就被影藏了，不太好看，所以延迟0.5s才影藏，于是就有下方的4.5s
        //如果把setTimeout里面的代码提出来放到外面，只是改变背景图片的内容和延迟执行时间，是达不到效果的，必须放到setTimeout函数里面，
        //目的是等上面执行完成了才改变背景图片，防在外面等于直接就改变了背景图片，不会有效果
        setTimeout( ()=> {
            this.configDomStyle('.page5','background-image:url('+this.dataObj.effectImages[4]+');opacity:0;transition: all 0.5s;');
            this.configDomStyle('.page4','background-image:url('+this.dataObj.effectImages[3]+');opacity:0;transition: all 0.5s;');
            this.configDomStyle('.page3','background-image:url('+this.dataObj.effectImages[2]+');opacity:0;transition: all 0.5s;');
            this.configDomStyle('.page2','background-image:url('+this.dataObj.effectImages[1]+');opacity:0;transition: all 0.5s;');
            //顶部的提示文字也影藏
            this.configDomStyle('.noteMsg','opacity:0;transition: all 1s;');
        },4500);
        let width=window.innerWidth;
        let height=window.innerHeight;
        //4、5s之后，让第一个li（书的背面）飞到左上角（上面都是4.5秒之后执行的，执行又要0.5秒，所以这里要在5秒之后执行）
        this.configDomStyle('.page1','background-image:url('+this.dataObj.effectImages[0]+');transform: translate(-'+(width+500)+"px,-"+(height+700)+'px) scale(0) rotate(45deg);transition: all 1.5s 5s;');
    }
}